import * as React from 'react';

// Components
import RealisationCard from '../../components/card/Realisation';

const RealisationsSection = ({ data, realisations }) => {
  return (
    <>
      <div className="row gx-3 mb-7">
        {realisations.nodes &&
          realisations.nodes.map(realisation => (
            <RealisationCard key={realisation.id} data={realisation} cardStyle="5" cardClassName="col-sm-6 col-lg-4 mb-4 mb-lg-0"/>
          ))
        }
      </div>
    </>
  );
};

export default RealisationsSection