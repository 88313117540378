import React, { useState, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const BusinessCardPlansSection = ({ data, plan, service }) => {

  const { t } = useTranslation();

  // Persons
  const [personsSelected] = useState({ label: plan.custom.persons.included, value: plan.custom.persons.included });

  // Mockups
  const [mockupsSelected] = useState({ label: plan.custom.mockups.included, value: plan.custom.mockups.included });

  // Feedbacks
  const [feedbacksSelected] = useState({ label: plan.custom.feedbacks.included, value: plan.custom.feedbacks.included });

  // Graphic Charter
  const [graphicCharterSelected] = useState({ label: t('simulator.sentence.graphicCharterOff'), value: 0 });

  // Simulator
  const [delivered, setDelivered] = useState(null);
  const [modifications, setModifications] = useState(null);

  // Update Simulator
  const updateSimulator = value => {

    // Plan
    var price = plan.price;

    // Persons
    if (plan.custom.persons.active === true && personsSelected.value > 0) {
      if (plan.custom.persons.included === 0) {
        price += plan.custom.persons.active_price;
        if (personsSelected.value > 1) {
          price += plan.custom.persons.additional_price * (personsSelected.value - 1);
        }
      } else {
        if (personsSelected.value > plan.custom.persons.included) {
          price += plan.custom.persons.active_price;
          if (personsSelected.value > plan.custom.persons.included) {
            price += plan.custom.persons.additional_price * (personsSelected.value - plan.custom.persons.included);
          }
        }
      }
    }

    // Mockups
    if (plan.custom.mockups.active === true && mockupsSelected.value > 0) {
      if (plan.custom.mockups.included === 0) {
        price += plan.custom.mockups.active_price;
        if (mockupsSelected.value > 1) {
          price += plan.custom.mockups.additional_price * (mockupsSelected.value - 1);
        }
      } else {
        if (mockupsSelected.value > plan.custom.mockups.included) {
          price += plan.custom.mockups.active_price;
          if (mockupsSelected.value > plan.custom.mockups.included) {
            price += plan.custom.mockups.additional_price * (mockupsSelected.value - plan.custom.mockups.included);
          }
        }
      }
    }

    // Feedbacks
    if (plan.custom.feedbacks.active === true && feedbacksSelected.value > 0) {
      if (plan.custom.feedbacks.included === 0) {
        price += plan.custom.feedbacks.active_price;
        if (feedbacksSelected.value > 1) {
          price += plan.custom.feedbacks.additional_price * (feedbacksSelected.value - 1);
        }
      } else {
        if (feedbacksSelected.value > plan.custom.feedbacks.included) {
          price += plan.custom.feedbacks.active_price;
          if (feedbacksSelected.value > plan.custom.feedbacks.included) {
            price += plan.custom.feedbacks.additional_price * (feedbacksSelected.value - plan.custom.feedbacks.included);
          }
        }
      }
    }

    // Delivered
    if (plan.custom.delivered.active === true) {
      var delivered = plan.custom.delivered.create;

      // Persons
      if (personsSelected.value > 0) {
        delivered += plan.custom.delivered.person * personsSelected.value;
      }

      // Mockups
      if (mockupsSelected.value > 0) {
        delivered += plan.custom.delivered.mockup * mockupsSelected.value;
      }

      // Feedbacks
      if (feedbacksSelected.value > 0) {
        delivered += plan.custom.delivered.feedback * feedbacksSelected.value;
      }

      // Graphic Charter
      if (graphicCharterSelected.value === 1) {
        delivered += plan.custom.delivered.graphic_charter * graphicCharterSelected.value;
      }

      setDelivered(Math.ceil(delivered));
    }

    // Modifications
    if (plan.custom.modifications.active === true) {
      var modifications = Math.floor(price / plan.custom.modifications.every_price);
      if (modifications > plan.custom.modifications.number_maximum) {
        modifications = plan.custom.modifications.number_maximum;
      } else if (modifications < plan.custom.modifications.number_minimum) {
        modifications = plan.custom.modifications.number_minimum;
      }

      setModifications(Math.ceil(modifications));
    }

  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    updateSimulator();
  });

  return (
    <>
      {service === 'business_card' ?
        <div className="table-responsive w-lg-75 mx-lg-auto" id="plans">
          <table className="table table-lg table-striped table-borderless table-nowrap table-vertical-border-striped">
            <thead className="table-text-center align-middle">
              <tr>
                <th scope="col" style={{ width: '40%' }}></th>
                <th scope="col" style={{ width: '60%' }}>
                  <span className="d-block" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.businessCard.allTaxesExcluded')} dangerouslySetInnerHTML={{ __html: t('plans.businessCard.from', { service_price: plan.price }) }}></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <span>{t('plans.businessCard.persons')}</span> <span className="bi bi-info-circle pointer" data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-title={t('plans.businessCard.personsPopoverTitle')} data-bs-content={t('plans.businessCard.personsPopoverContent')}></span>
                </th>
                <td className="table-text-center align-middle">
                  {plan.custom.persons.active === true &&
                    <div><span className="badge bg-soft-primary text-primary rounded-pill" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.businessCard.personTooltip')}>{plan.custom.persons.included}</span></div>
                  }
                  {plan.custom.persons.active === true && plan.custom.persons.number_maximum > 1 &&
                    <div><span className="text-muted small" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.businessCard.allTaxesExcluded')}>{t('plans.businessCard.personAdditionalPrice', { service_person_additional_price: plan.custom.persons.additional_price })}</span></div>
                  }
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <span>{t('plans.businessCard.mockups')}</span> <span className="bi bi-info-circle pointer" data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-title={t('plans.businessCard.mockupsPopoverTitle')} data-bs-content={t('plans.businessCard.mockupsPopoverContent')}></span>
                </th>
                <td className="table-text-center align-middle">
                  {plan.custom.mockups.active === true &&
                    <div><span className="badge bg-soft-primary text-primary rounded-pill" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.businessCard.mockupTooltip')}>{plan.custom.mockups.included}</span></div>
                  }
                  {plan.custom.mockups.active === true && plan.custom.mockups.number_maximum > 1 &&
                    <div><span className="text-muted small" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.businessCard.allTaxesExcluded')}>{t('plans.businessCard.mockupAdditionalPrice', { service_mockup_additional_price: plan.custom.mockups.additional_price })}</span></div>
                  }
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <span>{t('plans.businessCard.feedbacks')}</span> <span className="bi bi-info-circle pointer" data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-title={t('plans.businessCard.feedbacksPopoverTitle')} data-bs-content={t('plans.businessCard.feedbacksPopoverContent')}></span>
                </th>
                <td className="table-text-center align-middle">
                  {plan.custom.feedbacks.active === true &&
                    <div><span className="badge bg-soft-primary text-primary rounded-pill" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.businessCard.feedbackTooltip')}>{plan.custom.feedbacks.included}</span></div>
                  }
                  {plan.custom.feedbacks.active === true && plan.custom.feedbacks.number_maximum > 1 &&
                    <div><span className="text-muted small" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.businessCard.allTaxesExcluded')}>{t('plans.businessCard.feedbackAdditionalPrice', { service_feedback_additional_price: plan.custom.feedbacks.additional_price })}</span></div>
                  }
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <span>{t('plans.businessCard.graphicCharter')}</span> <span className="bi bi-info-circle pointer" data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-title={t('plans.businessCard.graphicCharterPopoverTitle')} data-bs-content={t('plans.businessCard.graphicCharterPopoverContent')}></span>
                </th>
                <td className="table-text-center align-middle">
                  <div className="pb-2">
                    <div><span className="text-muted small" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.businessCard.allTaxesExcluded')}>{t('plans.businessCard.graphicCharterActivePrice', { service_graphic_charter_active_price: plan.custom.graphic_charter.active_price })}</span></div>
                  </div>
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <div><span> {t('plans.businessCard.typographies')}</span></div>
                  <div><span className="text-muted small">{t('plans.businessCard.typographiesDescription')}</span></div>
                </th>
                <td className="table-text-center align-middle">
                  <div><i className="bi-check-circle text-success"></i></div>
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <div><span> {t('plans.businessCard.colorPallet')}</span></div>
                  <div><span className="text-muted small">{t('plans.businessCard.colorPalletDescription')}</span></div>
                </th>
                <td className="table-text-center align-middle">
                  <div><i className="bi-check-circle text-success"></i></div>
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">
                  <div><span> {t('plans.businessCard.formats')}</span></div>
                  <div><span className="text-muted small">{t('plans.businessCard.formatsDescription')}</span></div>
                </th>
                <td className="table-text-center align-middle">
                  <div><i className="bi-check-circle text-success"></i></div>
                </td>
              </tr>
              <tr className="border-bottom">
                <th scope="row" className="text-dark align-middle">{t('plans.businessCard.deliveredWithin')} <span className="bi bi-info-circle pointer" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.businessCard.deliveredWithinTooltip')}></span></th>
                <td className="table-text-center align-middle">
                  {delivered && plan.custom.delivered.active === true &&
                    <span>{t('plans.businessCard.deliveredWithinWorkingDays', { count: delivered, working_days_delivered: delivered })}</span>
                  }
                </td>
              </tr>
              <tr className="border-top border-bottom">
                <th scope="row" className="text-dark align-middle">{t('plans.businessCard.modificationsAfterDelivery')} <span className="bi bi-info-circle pointer" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('plans.businessCard.modificationsAfterDeliveryTooltip')}></span></th>
                <td className="table-text-center align-middle">
                  {modifications && plan.custom.modifications.active === true &&
                    <span className="badge bg-soft-primary text-primary rounded-pill">{modifications}</span>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        : null}
    </>
  );
};

export default BusinessCardPlansSection;
