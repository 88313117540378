import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Select from 'react-select';
import IntlTelInput from 'react-intl-tel-input';

const BusinessCardSimulatorSection = ({ data, plan, service }) => {

  const { t } = useTranslation();
  const { language } = useI18next();

  // Persons
  const [personsSelected, setPersonsSelected] = useState({ label: plan.custom.persons.included, value: plan.custom.persons.included });

  // Persons Options
  var optionsPersonsList = [];
  for (var i1 = plan.custom.persons.number_minimum; i1 <= plan.custom.persons.number_maximum; i1++) {
    optionsPersonsList.push({ label: i1, value: i1 })
  }
  const [optionsPersons] = useState(optionsPersonsList);

  // Handle Change Persons
  const handleChangePersons = (obj) => {
    setPersonsSelected(obj);
  };

  // Mockups
  const [mockupsSelected, setMockupsSelected] = useState({ label: plan.custom.mockups.included, value: plan.custom.mockups.included });

  // Mockups Options
  var optionsMockupsList = [];
  for (var i2 = plan.custom.mockups.number_minimum; i2 <= plan.custom.mockups.number_maximum; i2++) {
    optionsMockupsList.push({ label: i2, value: i2 })
  }
  const [optionsMockups] = useState(optionsMockupsList);

  // Handle Change Mockups
  const handleChangeMockups = (obj) => {
    setMockupsSelected(obj);
  };

  // Feedbacks
  const [feedbacksSelected, setFeedbacksSelected] = useState({ label: plan.custom.feedbacks.included, value: plan.custom.feedbacks.included });

  // Feedbacks Options
  var optionsFeedbacksList = [];
  for (var i3 = plan.custom.feedbacks.number_minimum; i3 <= plan.custom.feedbacks.number_maximum; i3++) {
    optionsFeedbacksList.push({ label: i3, value: i3 })
  }
  const [optionsFeedbacks] = useState(optionsFeedbacksList);

  // Handle Change Feedbacks
  const handleChangeFeedbacks = (obj) => {
    setFeedbacksSelected(obj);
  };

  // Graphic Charter
  const [graphicCharterSelected, setGraphicCharterSelected] = useState({ label: t('simulator.sentence.graphicCharterOff'), value: 0 });

  // Graphic Charter Options
  const optionsGraphicCharter = [
    { value: 0, label: t('simulator.sentence.graphicCharterOff') },
    { value: 1, label: t('simulator.sentence.graphicCharterOn') }
  ]

  // Handle Change Graphic Charter
  const handleChangeGraphicCharter = (obj) => {
    setGraphicCharterSelected(obj);
  };

  // Simulator
  const [price, setPrice] = useState(null);
  const [delivered, setDelivered] = useState(null);
  const [modifications, setModifications] = useState(null);

  // Update Simulator
  const updateSimulator = value => {

    // Plan
    var price = plan.price;

    // Persons
    if (plan.custom.persons.active === true && personsSelected.value > 0) {
      if (plan.custom.persons.included === 0) {
        price += plan.custom.persons.active_price;
        if (personsSelected.value > 1) {
          price += plan.custom.persons.additional_price * (personsSelected.value - 1);
        }
      } else {
        if (personsSelected.value > plan.custom.persons.included) {
          price += plan.custom.persons.active_price;
          if (personsSelected.value > plan.custom.persons.included) {
            price += plan.custom.persons.additional_price * (personsSelected.value - plan.custom.persons.included);
          }
        }
      }
    }

    // Mockups
    if (plan.custom.mockups.active === true && mockupsSelected.value > 0) {
      if (plan.custom.mockups.included === 0) {
        price += plan.custom.mockups.active_price;
        if (mockupsSelected.value > 1) {
          price += plan.custom.mockups.additional_price * (mockupsSelected.value - 1);
        }
      } else {
        if (mockupsSelected.value > plan.custom.mockups.included) {
          price += plan.custom.mockups.active_price;
          if (mockupsSelected.value > plan.custom.mockups.included) {
            price += plan.custom.mockups.additional_price * (mockupsSelected.value - plan.custom.mockups.included);
          }
        }
      }
    }

    // Feedbacks
    if (plan.custom.feedbacks.active === true && feedbacksSelected.value > 0) {
      if (plan.custom.feedbacks.included === 0) {
        price += plan.custom.feedbacks.active_price;
        if (feedbacksSelected.value > 1) {
          price += plan.custom.feedbacks.additional_price * (feedbacksSelected.value - 1);
        }
      } else {
        if (feedbacksSelected.value > plan.custom.feedbacks.included) {
          price += plan.custom.feedbacks.active_price;
          if (feedbacksSelected.value > plan.custom.feedbacks.included) {
            price += plan.custom.feedbacks.additional_price * (feedbacksSelected.value - plan.custom.feedbacks.included);
          }
        }
      }
    }

    // Graphic Charter
    if (plan.custom.graphic_charter.active === true && plan.custom.graphic_charter.included === false && graphicCharterSelected.value === 1) {
      price += plan.custom.graphic_charter.active_price;
    }

    setPrice(price);

    // Delivered
    if (plan.custom.delivered.active === true) {
      var delivered = plan.custom.delivered.create;

      // Persons
      if (personsSelected.value > 0) {
        delivered += plan.custom.delivered.person * personsSelected.value;
      }

      // Mockups
      if (mockupsSelected.value > 0) {
        delivered += plan.custom.delivered.mockup * mockupsSelected.value;
      }

      // Feedbacks
      if (feedbacksSelected.value > 0) {
        delivered += plan.custom.delivered.feedback * feedbacksSelected.value;
      }

      // Graphic Charter
      if (graphicCharterSelected.value === 1) {
        delivered += plan.custom.delivered.graphic_charter * graphicCharterSelected.value;
      }

      setDelivered(Math.ceil(delivered));
    }

    // Modifications
    if (plan.custom.modifications.active === true) {
      var modifications = Math.floor(price / plan.custom.modifications.every_price);
      if (modifications > plan.custom.modifications.number_maximum) {
        modifications = plan.custom.modifications.number_maximum;
      } else if (modifications < plan.custom.modifications.number_minimum) {
        modifications = plan.custom.modifications.number_minimum;
      }

      setModifications(Math.ceil(modifications));
    }

    // Message
    var message = t('simulator.contact.message.service');
    message += '<br>';
    message += t('simulator.contact.message.iNeed');
    if (plan.custom.persons.active === true) {
      message += '<br>';
      message += t('simulator.contact.message.persons', { count: personsSelected.value });
    }
    if (plan.custom.mockups.active === true) {
      message += '<br>';
      message += t('simulator.contact.message.mockups', { count: mockupsSelected.value });
    }
    if (plan.custom.feedbacks.active === true) {
      message += '<br>';
      message += t('simulator.contact.message.feedbacks', { count: feedbacksSelected.value });
    }
    if (plan.custom.graphic_charter.active === true) {
      message += '<br>';
      message += t('simulator.contact.message.graphicCharter', { count: graphicCharterSelected.value });
    }
    message += '<br>';
    message += t('simulator.contact.message.price', { service_price: price });
    if (plan.custom.delivered.active === true) {
      message += '<br>';
      message += t('simulator.contact.message.delivered', { count: Math.ceil(delivered) });
    }
    setValue("message", message);

  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    updateSimulator();
  });

  const selectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      color: '#377dff',
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none"
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#377dff',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "1rem"
    }),
  }

  const selectTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#f3f3f3",
      primary: "#377dff",
      danger: '#ed4c78',
      dangerLight: '#f5ca99',
      neutral0: '#ffffff',
      neutral5: '#f7faff',
      neutral10: '#f8fafd',
      neutral20: "rgba(33, 50, 91, 0.1)",
      neutral30: '#bdc5d1',
      neutral40: '#97a4af',
      neutral50: '#8c98a4',
      neutral60: '#677788',
      neutral70: '#71869d',
      neutral80: '#1e2022',
      neutral90: '#21325b',
    },
    // Other options you can use
    border: 0,
    borderRadius: 4,
    boxShadow: 'none',
  });

  // Telephone
  const handleChangeTelephone = (status, phoneNumber, country) => {
    setValue("telephone", phoneNumber)
  };

  const handleBlurTelephone = (status, phoneNumber, country) => {
    setValue("telephone", phoneNumber)
  };

  // Submit
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    reset,
    formState: { errors, isSubmitting }
  } = useForm();

  const onSubmit = async data => {
    await fetch(process.env.GATSBY_API_URL + 'messages', {
      method: "POST",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.GATSBY_API_KEY,
        "api-secret-key": process.env.GATSBY_API_SECRET_KEY,
        "form-uuid": process.env.GATSBY_FORM_SIMULATOR_UUID,
        "language-code": language,
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(result => {
        if (result.error === false) {
          setSubmitted(true);
          reset();
        } else if (result.error === true) {
          setError(
            "submit",
            "submitError",
          );
        }
      })
      .catch(error => {
        setError(
          "submit",
          "submitError",
          `${t('simulator.contact.form.error')} ${error.message}`
        );
      });
  };

  const showSubmitError = msg => <p className="text-danger">{t('simulator.contact.form.error')}</p>

  const showSubmitted = (
    <div className="m-4 text-center">
      <h3>{t('simulator.contact.form.sent.title')}</h3>
      <StaticImage className="img-fluid w-50" quality="30" src="../../../images/illustrations/la-small-agency-message.svg" alt="Image Description" />
      <p>{t('simulator.contact.form.sent.subtitle')}</p>
    </div>
  );

  const showForm = (
    <>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <div className="m-4">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  className="form-control form-control-sm"
                  placeholder={t('simulator.contact.form.firstName.placeholder')}
                  maxLength="100"
                  {...register("first_name", { required: true })}
                  disabled={isSubmitting}
                />
                {errors.first_name && <div className="text-danger">{t('simulator.contact.form.firstName.required')}</div>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  className="form-control form-control-sm"
                  placeholder={t('simulator.contact.form.lastName.placeholder')}
                  maxLength="100"
                  {...register("last_name", { required: true })}
                  disabled={isSubmitting}
                />
                {errors.last_name && <div className="text-danger">{t('simulator.contact.form.lastName.required')}</div>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control form-control-sm"
                  placeholder={t('simulator.contact.form.email.placeholder')}
                  maxLength="255"
                  {...register("email", { required: true })}
                  disabled={isSubmitting}
                />
                {errors.email && <div className="text-danger">{t('simulator.contact.form.email.required')}</div>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <Controller
                  name="telephone"
                  id="telephone"
                  maxLength="20"
                  rules={{ required: false }}
                  disabled={isSubmitting}
                  control={control}
                  render={({ field }) => (
                    <IntlTelInput
                      inputClassName="form-control form-control-sm"
                      nationalMode={false}
                      autoPlaceholder={true}
                      autoHideDialCode={false}
                      format
                      formatOnInit={true}
                      preferredCountries={["fr", "gb", "ru"]}
                      onPhoneNumberChange={handleChangeTelephone}
                      onPhoneNumberBlur={handleBlurTelephone}
                      placeholder={t('simulator.contact.form.telephone.placeholder')}
                    />
                  )}
                />
                {errors.telephone && <div className="text-danger">{t('simulator.contact.form.telephone.required')}</div>}
              </div>
            </div>
            <textarea
              className="d-none"
              name="message"
              id="message"
              maxLength="1000"
              {...register("message", { required: false })}
              disabled={isSubmitting}
            >
            </textarea>
          </div>
        </div>
        <div className="text-center">
          {errors && errors.submit && showSubmitError(errors.submit.message)}
        </div>
        <button type="submit" className="card-footer card-link bg-light btn btn-ghost-primary text-center w-100" disabled={isSubmitting}>{t('simulator.contact.form.send')}<span className="bi-send small ms-1"></span></button>
      </form>
    </>
  );

  return (
    <div>
      {service === 'business_card' ?
        <div className="row gx-0 align-items-md-start" id="plans">
          <div className="col-md-8 mb-3 mb-md-0">
            <div className="card card-lg zi-2" data-aos="fade-up">
              <div className="card-body">
                <div className="d-flex flex-wrap lead text-dark">
                  <div className="">
                    <span>{t('simulator.sentence.iNeed')}</span>
                    {plan.custom.persons.active === true &&
                      <span>
                        {plan.custom.persons.number_maximum > 1 &&
                          <span>
                            <Controller
                              name="persons"
                              id="persons"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={personsSelected}
                                  options={optionsPersons}
                                  onChange={handleChangePersons}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                />
                              )}
                            />
                            <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.personsPopoverContent')}>
                              <span>{t('simulator.sentence.persons', { count: personsSelected.value })}</span>
                            </span>
                          </span>
                        }
                      </span>
                    }
                    {plan.custom.mockups.active === true &&
                      <span>
                        {plan.custom.mockups.number_maximum > 1 &&
                          <span>
                            <Controller
                              name="mockups"
                              id="mockups"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={mockupsSelected}
                                  options={optionsMockups}
                                  onChange={handleChangeMockups}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                />
                              )}
                            />
                            <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.mockupsPopoverContent')}>
                              <span>{t('simulator.sentence.mockups', { count: mockupsSelected.value })}</span>
                            </span>
                          </span>
                        }
                      </span>
                    }
                    {plan.custom.feedbacks.active === true &&
                      <span>
                        <Controller
                          name="feedbacks"
                          id="feedbacks"
                          rules={{ required: false }}
                          disabled={isSubmitting}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="d-inline-block"
                              cacheOptions
                              defaultOptions
                              value={feedbacksSelected}
                              options={optionsFeedbacks}
                              onChange={handleChangeFeedbacks}
                              styles={selectCustomStyles}
                              theme={selectTheme}
                              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            />
                          )}
                        />
                        <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.feedbacksPopoverContent')}>
                          <span>{t('simulator.sentence.feedbacks', { count: feedbacksSelected.value })}</span>
                        </span>
                      </span>
                    }
                    {plan.custom.graphic_charter.active === true &&
                      <span>
                        <Controller
                          name="graphicCharter"
                          id="graphicCharter"
                          rules={{ required: false }}
                          disabled={isSubmitting}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="d-inline-block"
                              cacheOptions
                              defaultOptions
                              value={graphicCharterSelected}
                              options={optionsGraphicCharter}
                              onChange={handleChangeGraphicCharter}
                              styles={selectCustomStyles}
                              theme={selectTheme}
                              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            />
                          )}
                        />
                        <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.graphicCharterPopoverContent')}>
                          <span>{t('simulator.sentence.graphicCharter', { count: graphicCharterSelected.value })}</span>
                        </span>
                      </span>
                    }
                  </div>
                </div>
              </div>
              <hr className="mt-0 mb-0" />
              <div>{submitted ? showSubmitted : showForm}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-lg bg-dark ms-md-n2" data-aos="fade-up" data-aos-delay="200">
              <div className="card-body text-center">
                <div className="card-text text-white-70">{t('simulator.from')}</div>
                <div className="mb-3" data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.allTaxesExcluded')}>
                  {price &&
                    <div className="text-white"><span className="display-1">{t('simulator.price', { service_price: price })}</span></div>
                  }
                </div>
                {delivered && plan.custom.delivered.active === true &&
                  <div className="card-text text-white-70 small">{t('simulator.deliveredWithinWorkingDays', { count: delivered, working_days_delivered: delivered })}</div>
                }
                {modifications && plan.custom.modifications.active === true &&
                  <div className="card-text text-white-70 small" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('simulator.modificationsAfterDeliveryTooltip')}>{t('simulator.modificationsAfterDelivery', { count: modifications, modifications_after_delivery: modifications })}</div>
                }
              </div>
            </div>
          </div>
        </div>
        : null}
    </div>
  );

};

export default BusinessCardSimulatorSection;
